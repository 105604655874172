@media (min-width: 1921px) and (max-width: 2560px) {
  .container-fluid {
    width: 1920px !important;
  }

  .service-our-service-item-holder-content .guardian-holder-content-left {
    width: 800px;
  }
}

@media only screen and (max-width: 1920px) {
  .service-our-service-item-holder-content .guardian-holder-content-left {
    width: 650px;
  }
}

@media only screen and (max-width: 1919px) {
  .shields-section-holder
    .shields-section-content-list-items
    .shields-section-content-item {
    // width: 265px;
    // margin-right: 41px;
  }
}

@media only screen and (max-width: 1746px) {
  .shields-section-holder
    .shields-section-content-list-items
    .shields-section-content-item {
    // width: 260px;
    // margin-right: 41px;
  }
}

@media only screen and (max-width: 1680px) {
  img.img-fluid.img-right-side-partners {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .soc-section-holder .soc-section-holder-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 80px;
  }

  .service-our-service-item-holder-content .content-our-service-content-holder {
    background: #f2f0ed 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 74px 105px 68px 75px;
  }

  .footer-content-holder.des-block .footer-social-item {
    display: flex;
    margin-left: 60px;
  }

  .footer-content-holder.des-block li {
    color: #fff;
    display: inline-block;
    font: normal normal normal 15px/26px RalewayRegular;
    letter-spacing: 0;
    margin-left: 35px;
    opacity: 1;
    text-align: left;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shields-section-content-item {
    // width: 247px;
    // margin-right: 41px;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shield-item-section
    h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    font: normal normal bold 35px/61px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    opacity: 1;
    transform: translate(-50%, -70%);
  }

  .why-section-holder .why-section-holder-content {
    padding-top: 200px;
    padding-bottom: 130px;
  }

  .header-content-main li {
    font-family: "RalewayRegular";
    font-size: 17px;
    display: inline-block;
    margin-right: 60px;
  }

  .soc-section-holder .soc-section-content-left {
    // flex: 1 1;
    // margin-right: 50px;
  }

  .client-item {
    height: 260px;
    width: 215px px;
    margin: 0 auto;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shield-item-section
    h3 {
    font: normal normal bold 15px/26px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 211px;
  }
}

@media only screen and (max-width: 1600px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    // --bs-gutter-x: 11.5rem !important;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1 {
    font: normal normal bold 127px/174px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
    max-width: 1260px;
  }

  .hero-section-holder.des-block .hero-section-holder-content h2 {
    font: normal normal bold 56px/59px RalewayBold;
    color: rgb(219, 158, 124);
    letter-spacing: 0px;
    opacity: 0.78;
    margin-bottom: 35px;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1::before {
    background: transparent
      linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
    content: "";
    height: 123px;
    width: 369px;
    position: absolute;
    top: 28px;
    left: -5px;
    z-index: -1;
  }
}

@media only screen and (max-width: 1537px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    // --bs-gutter-x: 10.5rem !important;
  }

  .soc-section-holder .soc-section-content-left {
    width: 560px;
    height: 400px;
    margin-right: 36px;
    position: relative;
  }

  .header-content-main li {
    font-family: "RalewayRegular";
    font-size: 15px;
    display: inline-block;
    margin-right: 37px;
  }

  .header-content-main .header-items-list-center img {
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 95px;
  }

  .header-content-main .header-items-list-center {
    width: 100px;
    height: 65px;
  }

  .header-content-main {
    background-color: #12032e;
    width: 100%;
    height: 65px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .sub-header-menu {
    position: fixed;
    width: 100%;
    top: 65px;
    left: 0;
    background: #12032e 0% 0% no-repeat padding-box;
    opacity: 0.78;
    display: none;
  }

  .header-content-main .header-items-list-holder::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 31px solid transparent;
    border-right: 31px solid transparent;
    border-bottom: 19px solid #12032f;
    top: 65px;
    left: 50%;
    transform: translate(-50%, 0) rotate(180deg);
  }

  .hero-section-holder.des-block .hero-section-holder-content h2 {
    font: normal normal bold 50px/59px RalewayBold;
    color: rgb(219, 158, 124);
    letter-spacing: 0px;
    opacity: 0.78;
    margin-bottom: 35px;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1 {
    // font: normal normal bold 123px/135px RalewayBold;
    // letter-spacing: 0px;
    // color: rgb(255, 255, 255);
    // text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    // opacity: 1;
    // margin-bottom: 45px;
    // position: relative;
    // z-index: 1;
    // border-right: 0.05em solid;
    // border-right-color: transparent linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0%
    //     no-repeat padding-box;
    // overflow: hidden;
    // white-space: nowrap;
    // max-width: 1260px;
    // animation:
    //     typing 3.5s steps(40, end),
    //     blink-caret 0.75s step-end infinite;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1::before {
    background: transparent
      linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
    content: "";
    height: 148px;
    width: 400px;
    position: absolute;
    top: 10px;
    left: -5px;
    z-index: -1;
  }

  .hero-section-holder.des-block .hero-section-holder-content h3 {
    font: normal normal normal 20px/24px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    width: 520px;
  }

  .custom-buttom-hero {
    background: transparent linear-gradient(91deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    color: #fff;
    padding: 20px 30px;
    font: normal normal bold 20px/21px RalewayBold;
    opacity: 0.79;
    position: fixed;
    bottom: 90px;
    right: 50px;
    z-index: 2;
  }

  .security-section-holder .security-section-holder-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 140px;
  }

  .security-section-holder {
    background: rgb(242, 240, 237) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.16);
    opacity: 1;
    height: 800px;
    position: relative;
  }

  .security-section-holder h3 {
    text-align: center;
    font: normal normal normal 16px/30px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(40, 25, 58);
    opacity: 1;
    margin-top: 53px;
    margin-bottom: 90px;
  }

  .shields-section-holder .shields-section-content {
    padding-left: 70px;
    margin-top: 95px;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shields-section-content-item {
    // width: 247px;
    // margin-right: 28px;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shield-item-section
    h2 {
    position: absolute;
    // top: 127px;
    // left: 90px;
    font: normal normal bold 30px/51px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    opacity: 1;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shield-item-section
    h3 {
    font: normal normal bold 15px/24px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 211px;
    padding: 0px 20px;
  }

  .shields-section-holder .shields-section-content h3 {
    width: 812px;
    text-align: left;
    font: normal normal normal 20px/30px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    margin-top: 50px;
    margin-bottom: 57px;
  }

  .shields-section-holder {
    background-repeat: no-repeat;
    background-size: cover;
    height: 950px;
  }

  .client-item {
    height: 260px;
    max-width: 185px;
    margin: 0 auto;
  }

  .soc-section-holder {
    padding: 200px 0px 130px 0px;
    background: rgb(242, 240, 237) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.16);
    opacity: 1;
  }

  .soc-section-holder .soc-section-holder-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
  }

  .soc-section-holder .soc-bckg-image {
    background-repeat: no-repeat;
    opacity: 1;
    width: 717px;
    height: 295px;
    position: absolute;
    top: -183px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 0);
    background-size: contain;
  }

  .soc-section-content-left img {
    width: 85%;
  }

  .resource-services-holder-content .resource-services-content-center {
    padding: 0 150px;
    text-align: center;
  }

  .why-section-holder .why-section-holder-content {
    padding-top: 130px;
    padding-bottom: 55px;
  }

  .why-section-holder .why-content-item-block {
    text-align: left;
    font: normal normal bold 37px/46px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 1;
    background: transparent linear-gradient(89deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    padding: 0px 18px 11px 18px;
  }

  .why-section-holder .why-section-content-right {
    flex: 1 1;
    margin-top: 50px;
    margin-left: 25px;
  }

  .partners-section-holder {
    padding-top: 90px;
    padding-bottom: 10px;
  }

  .partners-section-holder h2 {
    text-align: center;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: rgb(18, 3, 46);
    opacity: 1;
    margin-bottom: 81px;
  }

  .partners-section-holder .partner-item-left-side-holder {
    padding-left: 66px;
    padding-top: 64px;
    width: 575px;
  }

  .partners-section-holder .partner-item-left-side {
    height: 530px;
    width: 950px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .partners-section-holder .partner-item-left-right {
    height: 530px;
    width: 660px;
    margin-left: 20px;
    position: relative;
  }

  .partner-item-left-right .slick-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
  }

  .partners-section-holder .flickity-prev-next-button.previous {
    left: 70px;
    top: 70%;
    background: transparent;
  }

  .partners-section-holder .flickity-prev-next-button.next {
    top: 70%;
    right: 84%;
    background: transparent;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1 {
    font: normal normal bold 140px/181px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    margin-bottom: 45px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
    max-width: 1143px;
  }

  .footer-content-holder.des-block .footer-social-item {
    display: flex;
    margin-left: 0px;
  }

  .footer-content-holder.des-block .footer-reserved-item {
    text-align: left;
    font: normal normal normal 13px/15px MontserratRegular;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    opacity: 1;
    margin-left: 55px;
  }

  .footer-content-holder.des-block .right-side-footer {
    flex: 1.5;
    display: flex;
    justify-content: left;
    align-content: center;
  }

  .footer-content-holder.des-block .footer-logo-item img {
    height: 100%;
    width: 180px;
  }

  .footer-content-holder.des-block li {
    display: inline-block;
    text-align: left;
    font: normal normal normal 15px/17px RalewayRegular;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    margin-left: 37px;
  }

  .footer-content-holder.des-block {
    background: rgb(18, 3, 46) 0% 0% no-repeat padding-box;
    border: 1px solid rgb(112, 112, 112);
    opacity: 1;
    height: 85px;
  }

  .why-section-holder .list-items-why-content-item {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 19px;
  }

  .sub-header-menu .sub-header-menu-items li {
    font: normal normal bold 17px/60px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-right: 25px px;
    border-right: 1px solid #d900a1;
    padding-right: 25px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .main-content-site-holder {
    margin-top: 65px;
  }

  .service-hero-holder-content .show-first-animation h1 {
    text-align: left;
    font: normal normal bold 56px/76px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    position: relative;
    z-index: 1;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
    display: flex;
    align-items: baseline;
    position: relative;
  }

  .sub-header-menu .sub-header-menu-items ul {
    padding: 13px 0px;
    text-align: left;
  }

  .service-hero-holder-content .service-hero-holder {
    padding: 150px 0px 150px 120px;
    max-width: 1020px;
  }

  .hero-bottom-line-spacing {
    height: 65px;
    background: rgb(18, 3, 46);
    position: relative;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder-soc-left {
    flex: 1.7 1;
    padding-right: 55px;
  }

  .container-serive-image-text-block h1 {
    text-align: left;
    font: normal normal bold 47px/51px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 50px;
    max-width: 700px;
  }

  .custom-services-button {
    height: 42px;
    background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 18px/19px RalewayBold;
    letter-spacing: 1.8px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    text-align: center;
    padding: 0px 40px;
    margin-top: 50px;
    display: inline-block;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  .service-info-holder {
    padding: 100px 310px 0px 130px !important;
  }

  .service-our-service-item-holder-content .content-our-service-content-holder {
    background: #f2f0ed 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 74px 71px 113px 94px;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder
    p {
    text-align: left;
    font: normal normal normal 15px/31px MontserratRegular;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
  }

  .container-serive-image-text-block {
    padding: 120px 250px 100px 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .penetration-item-image {
    margin: 0px 55px;
  }

  .info-block-holder-content {
    background: #f2f0ed;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
    background: #f2f0ed 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.1607843137);
    opacity: 1;
  }

  .partner-item-image .img-fluid.img-right-side-partners {
    object-fit: cover;
    height: 100%;
  }

  .service-our-service-item-holder-content .penetration-item-title {
    text-align: center;
    font: normal normal bold 47px/51px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-top: 50px;
  }

  .service-our-service-item-holder-content .guardian-holder-content-left ul li {
    text-align: left;
    font: normal normal normal 16px/30px MontserratRegular;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-right: 55px;
  }

  .solutions-item-holder .solutions-item-main-block-content {
    padding: 95px 0px 81px 100px;
  }

  .service-our-service-item-holder-content .penetration-block-list-item {
    flex: 1 1;
    text-align: center;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder
    h2 {
    text-align: left;
    font: normal normal normal 47px/51px RalewayRegular;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-bottom: 60px;
  }

  .service-info-holder h3 {
    text-align: left;
    font: normal normal normal 19px/30px MontserratMedium;
    letter-spacing: 0px;
    color: #0a0a50;
  }

  .cyber-info-hero-holder-content h2 {
    text-align: left;
    font: normal normal bold 19px/30px MontserratBold;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
    margin-bottom: 35px;
  }

  .hero-bottom-line-spacing::before {
    content: "";
    z-index: 1;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 31px solid transparent;
    border-right: 31px solid transparent;
    border-bottom: 19px solid #12032f;
    top: 65px;
    left: 50%;
    transform: translate(-50%, 0) rotate(180deg);
  }

  .service-hero-holder-content .show-animation h1 {
    text-align: left;
    font: normal normal bold 46px/49px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    position: relative;
    z-index: 1;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
    display: flex;
    align-items: baseline;
    position: relative;
  }

  .images-block-content-holder .images-block-holder {
    flex: 1 1;
    padding: 65px 83px 125px 83px;
  }

  .block-image-item-holder .block-image-main-block-content {
    padding: 100px 0px 100px 139px;
  }

  .images-block-content-holder h2 {
    text-align: left;
    font: normal normal bold 52px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 55px;
    position: relative;
    z-index: 1;
  }

  .image-block-holder {
    text-align: center;
    margin-top: 110px;
    margin-bottom: 200px;
  }

  .image-block-holder-item-3.image-block-holder-item {
    position: absolute;
    top: 50%;
    right: -220px;
    transform: translate(0%, -50%);
  }

  .why-section-holder .why-section-content {
    display: flex;
    flex-direction: row;
    padding: 0px 50px;
  }

  .service-hero-holder-content h1::after {
    content: "";
    background: transparent linear-gradient(101deg, #d900a1 18%, #6517c8 100%)
      0% 0% no-repeat padding-box;
    opacity: 1;
    width: 26px;
    position: absolute;
    left: -13px;
    height: 90px;
    top: -10px;
    z-index: -1;
  }

  .expand-list-content-holder p {
    font: normal normal normal 20px/30px MontserratRegular;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
  }

  .expand-list-item-header h4 {
    font: normal normal bold 28px/46px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
  }

  .blog-block-content-holder h2 {
    text-align: left;
    height: 155px;
    font: normal normal bold 47px/51px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 55px;
    position: relative;
    z-index: 1;
  }

  .blog-detail-container img {
    height: 360px;
    object-fit: cover;
  }

  .blog-block-content-holder .blog-block-holder {
    width: calc(50% - 20px);
    padding: 50px 70px;
  }

  .title-description-holder-content h2 {
    font: normal normal bold 47px/51px RalewayBold;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    margin-bottom: 40px;
  }

  .mission-vision-content-holder-block .mission-vision-item {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mission-vision-content-holder-block .mission-item h1 {
    text-align: center;
    font: normal normal bold 100px/121px RalewayBold;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
    margin-bottom: 40px;
  }

  .related-services-holder-content .related-services-content-center {
    padding: 0px 100px;
    text-align: center;
  }

  .mission-vision-content-holder-block .vision-item h1 {
    text-align: center;
    font: normal normal bold 100px/121px RalewayBold;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
    margin-bottom: 40px;
  }

  .four-blocks-holder-content h3 {
    text-align: left;
    font: normal normal bold 45px/51px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-bottom: 40px;
  }

  .four-blocks-holder-content .four-blocks-item {
    flex: 1 1;
    flex-basis: calc(50% - 20px);
    background: #f2f0ed 0% 0% no-repeat padding-box;
    border: 1px solid rgba(0, 0, 0, 0);
    opacity: 1;
    padding: 60px 48px 100px 60px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .text-button-image-bckg-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 129px 0px;
  }

  .title-description-holder-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0px 25px 0px;
  }

  .cyber-info-hero-holder-content .cyber-info-hero-holder {
    padding: 77px 310px 54px 120px;
  }
}

@media only screen and (max-width: 1440px) {
  .soc-section-holder .soc-bckg-image {
    background-repeat: no-repeat;
    opacity: 1;
    width: 717px;
    height: 295px;
    position: absolute;
    top: -204px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 0);
    background-size: contain;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shields-section-content-item {
    // width: 228px;
    // margin-right: 28px;
  }

  .service-our-service-item-holder-content .content-our-service-content-holder {
    background: #f2f0ed 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 74px 50px 74px 50px;
  }

  .partner-item-left-right img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .partners-section-holder .partner-item-left-side {
    height: 530px;
    width: 950px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }

  .partners-section-holder .flickity-prev-next-button.previous {
    left: 70px;
    top: 74%;
    background: transparent;
  }

  .partners-section-holder .flickity-prev-next-button.next {
    top: 74%;
    right: 84%;
    background: transparent;
  }
}

@media only screen and (max-width: 1366px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    // --bs-gutter-x: 10.5rem !important;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder-soc-right {
    width: 500px;
    margin-top: 0px;
    position: relative;
  }

  .content-our-service-content-holder-soc-right
    .soc-section-content-left-item-3 {
    top: 12%;
  }

  .service-our-service-item-holder-content .guardian-holder-content-left {
    width: 100%;
    margin-bottom: 20px;
  }

  .guardian-holder-content {
    flex-direction: column;
  }

  .header-content-main li {
    font-family: "RalewayRegular";
    font-size: 15px;
    display: inline-block;
    margin-right: 37px;
  }

  .header-content-main .header-items-list-center img {
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 95px;
  }

  .header-content-main .header-items-list-center {
    width: 100px;
    height: 65px;
  }

  .header-content-main {
    background-color: #12032e;
    width: 100%;
    height: 65px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .sub-header-menu {
    position: fixed;
    width: 100%;
    top: 65px;
    left: 0;
    background: #12032e 0% 0% no-repeat padding-box;
    opacity: 0.78;
    display: none;
  }

  .header-content-main .header-items-list-holder::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 31px solid transparent;
    border-right: 31px solid transparent;
    border-bottom: 19px solid #12032f;
    top: 65px;
    left: 50%;
    transform: translate(-50%, 0) rotate(180deg);
  }

  .hero-section-holder.des-block .hero-section-holder-content h2 {
    font: normal normal bold 42px/59px RalewayBold;
    color: rgb(219, 158, 124);
    letter-spacing: 0px;
    opacity: 0.78;
    margin-bottom: 35px;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1 {
    // font: normal normal bold 123px/135px RalewayBold;
    // letter-spacing: 0px;
    // color: rgb(255, 255, 255);
    // text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    // opacity: 1;
    // margin-bottom: 45px;
    // position: relative;
    // z-index: 1;
    // border-right: 0.05em solid;
    // border-right-color: transparent linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0%
    //     no-repeat padding-box;
    // overflow: hidden;
    // white-space: nowrap;
    // max-width: 1260px;
    // animation:
    //     typing 3.5s steps(40, end),
    //     blink-caret 0.75s step-end infinite;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1::before {
    background: transparent
      linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
    content: "";
    height: 148px;
    width: 290px;
    position: absolute;
    top: 10px;
    left: -5px;
    z-index: -1;
  }

  .hero-section-holder.des-block .hero-section-holder-content h3 {
    font: normal normal normal 16px/24px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    width: 520px;
  }

  .custom-buttom-hero {
    background: transparent linear-gradient(91deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    color: #fff;
    padding: 20px 30px;
    font: normal normal bold 20px/21px RalewayBold;
    opacity: 0.79;
    position: fixed;
    bottom: 90px;
    right: 50px;
    z-index: 2;
  }

  .security-section-holder .security-section-holder-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 140px;
  }

  .security-section-holder {
    background: rgb(242, 240, 237) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.16);
    opacity: 1;
    height: 800px;
    position: relative;
  }

  .security-section-holder h3 {
    text-align: center;
    font: normal normal normal 16px/30px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(40, 25, 58);
    opacity: 1;
    margin-top: 53px;
    margin-bottom: 90px;
  }

  .shields-section-holder .shields-section-content {
    padding-left: 70px;
    margin-top: 95px;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shields-section-content-item {
    // width: 213px;
    // margin-right: 28px;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shield-item-section
    h2 {
    position: absolute;
    // top: 104px;
    // left: 90px;
    font: normal normal bold 30px/51px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    opacity: 1;
  }

  .soc-section-holder .soc-section-content-right h2 {
    font: normal normal bold 52px/54px RalewayBold;
    letter-spacing: 0px;
    color: rgb(18, 3, 46);
    opacity: 1;
    margin-bottom: 25px;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shield-item-section
    h3 {
    font: normal normal bold 15px/24px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 43%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 211px;
    padding: 0px 20px;
  }

  .shields-section-holder .shields-section-content h3 {
    width: 812px;
    text-align: left;
    font: normal normal normal 20px/30px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    margin-top: 50px;
    margin-bottom: 57px;
  }

  .shields-section-holder {
    background-repeat: no-repeat;
    background-size: cover;
    height: 900px;
  }

  .soc-section-holder {
    padding: 200px 0px 130px 0px;
    background: rgb(242, 240, 237) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.16);
    opacity: 1;
  }

  .soc-section-holder .soc-section-holder-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
  }

  .soc-section-holder .soc-bckg-image {
    background-repeat: no-repeat;
    opacity: 1;
    width: 673px;
    height: 295px;
    position: absolute;
    top: -200px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 0);
    background-size: contain;
  }

  .soc-section-content-left img {
    width: 90%;
  }

  .why-section-holder .why-section-holder-content {
    padding-top: 130px;
    padding-bottom: 30px;
  }

  .client-item {
    height: 260px;
    width: 120px;
    margin: 0 auto;
  }

  .why-section-holder .why-content-item-block {
    text-align: left;
    font: normal normal bold 37px/46px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 1;
    background: transparent linear-gradient(89deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    padding: 0px 18px 11px 18px;
  }

  .why-section-holder .why-section-content-right {
    flex: 1 1;
    margin-top: 50px;
    margin-left: 25px;
  }

  .partners-section-holder {
    padding-top: 90px;
    padding-bottom: 10px;
  }

  .partners-section-holder h2 {
    text-align: center;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: rgb(18, 3, 46);
    opacity: 1;
    margin-bottom: 81px;
  }

  .partners-section-holder .partner-item-left-side-holder {
    padding-left: 66px;
    padding-top: 64px;
    width: 575px;
  }

  .partners-section-holder .partner-item-left-side {
    height: 530px;
    width: 950px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .partners-section-holder .partner-item-left-right {
    height: 530px;
    width: 660px;
    margin-left: 20px;
    position: relative;
  }

  .partner-item-left-right .slick-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
  }

  .partners-section-holder .flickity-prev-next-button.previous {
    left: 70px;
    top: 75%;
    background: transparent;
  }

  .partners-section-holder .flickity-prev-next-button.next {
    top: 75%;
    right: 84%;
    background: transparent;
  }

  .partners-section-holder .partner-item-image {
    position: absolute;
    left: -130px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 260px;
    text-align: center;
  }

  .footer-content-holder.des-block .footer-social-item {
    display: flex;
    margin-left: 0px;
  }

  .footer-content-holder.des-block .footer-reserved-item {
    text-align: left;
    font: normal normal normal 13px/15px MontserratRegular;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    opacity: 1;
    margin-left: 35px;
  }

  .footer-content-holder.des-block .right-side-footer {
    flex: 1.5;
    display: flex;
    justify-content: left;
    align-content: center;
  }

  .footer-content-holder.des-block .footer-logo-item img {
    height: 100%;
    width: 180px;
  }

  .footer-content-holder.des-block li {
    display: inline-block;
    text-align: left;
    font: normal normal normal 15px/17px RalewayRegular;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    margin-left: 30px;
  }

  .footer-content-holder.des-block {
    background: rgb(18, 3, 46) 0% 0% no-repeat padding-box;
    border: 1px solid rgb(112, 112, 112);
    opacity: 1;
    height: 85px;
  }

  .why-section-holder .list-items-why-content-item {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 19px;
  }

  .sub-header-menu .sub-header-menu-items li {
    font: normal normal bold 17px/60px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-right: 25px px;
    border-right: 1px solid #d900a1;
    padding-right: 25px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .main-content-site-holder {
    margin-top: 65px;
  }

  .service-hero-holder-content .show-first-animation h1 {
    text-align: left;
    font: normal normal bold 56px/76px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    position: relative;
    z-index: 1;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
    display: flex;
    align-items: baseline;
    position: relative;
  }

  .sub-header-menu .sub-header-menu-items ul {
    padding: 13px 0px;
    text-align: left;
  }

  .service-hero-holder-content .service-hero-holder {
    padding: 150px 0px 150px 120px;
    max-width: 1020px;
  }

  .hero-bottom-line-spacing {
    height: 65px;
    background: rgb(18, 3, 46);
    position: relative;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder-soc-left {
    flex: 1.7 1;
    padding-right: 55px;
  }

  .container-serive-image-text-block h1 {
    text-align: left;
    font: normal normal bold 47px/51px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 50px;
    max-width: 700px;
  }

  .custom-services-button {
    height: 42px;
    background: transparent linear-gradient(90deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 18px/19px RalewayBold;
    letter-spacing: 1.8px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    text-align: center;
    padding: 0px 40px;
    margin-top: 50px;
    display: inline-block;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  .service-info-holder {
    padding: 100px 310px 0px 130px !important;
  }

  .service-our-service-item-holder-content .content-our-service-content-holder {
    background: #f2f0ed 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 74px 71px 113px 94px;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder
    p {
    text-align: left;
    font: normal normal normal 15px/31px MontserratRegular;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
  }

  .container-serive-image-text-block {
    padding: 120px 250px 100px 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .penetration-item-image {
    margin: 0px 55px;
  }

  .info-block-holder-content {
    background: #f2f0ed;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
    background: #f2f0ed 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.1607843137);
    opacity: 1;
  }

  .partner-item-image .img-fluid.img-right-side-partners {
    object-fit: cover;
    height: 100%;
  }

  .service-our-service-item-holder-content .penetration-item-title {
    text-align: center;
    font: normal normal bold 47px/51px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-top: 50px;
  }

  .service-our-service-item-holder-content .guardian-holder-content-left ul li {
    text-align: left;
    font: normal normal normal 16px/30px MontserratRegular;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-right: 55px;
  }

  .solutions-item-holder .solutions-item-main-block-content {
    padding: 95px 0px 81px 100px;
  }

  .service-our-service-item-holder-content .penetration-block-list-item {
    flex: 1 1;
    text-align: center;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder
    h2 {
    text-align: left;
    font: normal normal normal 47px/51px RalewayRegular;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-bottom: 60px;
  }

  .service-info-holder h3 {
    text-align: left;
    font: normal normal normal 19px/30px MontserratMedium;
    letter-spacing: 0px;
    color: #0a0a50;
  }

  .cyber-info-hero-holder-content h2 {
    text-align: left;
    font: normal normal bold 19px/30px MontserratBold;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
    margin-bottom: 35px;
  }

  .hero-bottom-line-spacing::before {
    content: "";
    z-index: 1;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 31px solid transparent;
    border-right: 31px solid transparent;
    border-bottom: 19px solid #12032f;
    top: 65px;
    left: 50%;
    transform: translate(-50%, 0) rotate(180deg);
  }

  .service-hero-holder-content .show-animation h1 {
    text-align: left;
    font: normal normal bold 46px/49px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    position: relative;
    z-index: 1;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
    display: flex;
    align-items: baseline;
    position: relative;
  }

  .images-block-content-holder .images-block-holder {
    flex: 1 1;
    padding: 65px 83px 125px 83px;
  }

  .block-image-item-holder .block-image-main-block-content {
    padding: 100px 0px 100px 139px;
  }

  .images-block-content-holder h2 {
    text-align: left;
    font: normal normal bold 52px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 55px;
    position: relative;
    z-index: 1;
  }

  .image-block-holder {
    text-align: center;
    margin: 80px 150px;
  }

  .why-section-holder .why-section-content {
    display: flex;
    flex-direction: row;
    padding: 0px 50px;
  }

  .expand-list-content-holder p {
    font: normal normal normal 20px/30px MontserratRegular;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    padding: 0px 50px 15px;
  }

  .expand-list-item-header h4 {
    font: normal normal bold 28px/46px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
  }

  .blog-block-content-holder h2 {
    text-align: left;
    height: 138px;
    font: normal normal bold 33px/36px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    -webkit-line-clamp: 4;
  }

  .blog-block-content-holder .blog-block-holder {
    width: calc(50% - 20px);
    padding: 50px 70px;
  }

  .title-description-holder-content h2 {
    font: normal normal bold 47px/51px RalewayBold;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    margin-bottom: 40px;
  }

  .mission-vision-content-holder-block .mission-vision-item {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mission-vision-content-holder-block .mission-item h1 {
    text-align: center;
    font: normal normal bold 100px/121px RalewayBold;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
    margin-bottom: 40px;
  }

  .related-services-holder-content .related-services-content-center {
    padding: 0px 55px;
    text-align: center;
  }

  .mission-vision-content-holder-block .vision-item h1 {
    text-align: center;
    font: normal normal bold 100px/121px RalewayBold;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
    margin-bottom: 40px;
  }

  .four-blocks-holder-content h3 {
    text-align: left;
    font: normal normal bold 45px/51px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-bottom: 40px;
  }

  .four-blocks-holder-content .four-blocks-item {
    flex: 1 1;
    flex-basis: calc(50% - 20px);
    background: #f2f0ed 0% 0% no-repeat padding-box;
    border: 1px solid rgba(0, 0, 0, 0);
    opacity: 1;
    padding: 60px 48px 100px 60px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .text-button-image-bckg-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 129px 0px;
  }

  .title-description-holder-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0px 25px 0px;
  }

  .cyber-info-hero-holder-content .cyber-info-hero-holder {
    padding: 77px 310px 54px 120px;
  }

  .related-services-holder-content .related-services-item {
    padding: 40px 25px;
    border-right: 1px solid #fff;
    flex: 1 1;
    text-align: center;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1 {
    font: normal normal bold 98px/116px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    margin-bottom: 45px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
    max-width: 1143px;
  }
}

@media only screen and (max-width: 1280px) {
  .service-our-service-item-holder-content
    .content-our-service-content-holder-soc {
    flex-direction: column;
  }

  .hero-section-holder.des-block .hero-section-holder-content h2 {
    font: normal normal bold 50px/58px RalewayBold;
    color: rgb(219, 158, 124);
    letter-spacing: 0px;
    opacity: 0.78;
    margin-bottom: 35px;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder-soc-right {
    width: 500px;
    margin-top: 120px;
    position: relative;
    height: 300px;
  }

  .content-our-service-content-holder-soc-right
    .soc-section-content-left-item-3 {
    top: -35%;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1 {
    font: normal normal bold 95px/140px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    margin-bottom: 45px;
    position: relative;
    z-index: 1;
  }

  .hero-section-holder.des-block .hero-section-holder-content h1::before {
    background: transparent
      linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
    content: "";
    height: 90px;
    width: 280px;
    position: absolute;
    top: 28px;
    left: -5px;
    z-index: -1;
  }

  .hero-section-holder.des-block .hero-section-holder-content h3 {
    font: normal normal normal 20px/24px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    width: 500px;
  }

  .hero-section-holder.des-block .hero-section-holder-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    // --bs-gutter-x: 8.5rem !important;
  }

  .header-content-main li {
    font-family: "RalewayRegular";
    font-size: 15px;
    display: inline-block;
    margin-right: 30px;
  }

  .security-section-holder {
    background: rgb(242, 240, 237) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.16);
    opacity: 1;
    height: 700px;
    position: relative;
  }

  .security-section-holder h2 {
    text-align: center;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: rgb(18, 3, 46);
    opacity: 1;
  }

  .shields-section-holder .shields-section-content {
    padding-left: 60px;
    margin-top: 100px;
  }

  .shields-section-holder .shields-section-content h2 {
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shields-section-content-item {
    // width: 204px;
    // margin-right: 26px;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shield-item-section
    h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    font: normal normal bold 32px/61px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    opacity: 1;
  }

  .shields-section-holder .shields-section-content h3 {
    width: 812px;
    text-align: left;
    font: normal normal normal 20px/30px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .shields-section-holder {
    background-repeat: no-repeat;
    background-size: cover;
    height: 900px;
  }

  .shields-section-holder
    .shields-section-content-list-items
    .shield-item-section
    h3 {
    font: normal normal bold 14px/21px MontserratMedium;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0px 37px;
  }

  .soc-section-holder {
    padding: 150px 0px 100px 0px;
    background: rgb(242, 240, 237) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.16);
    opacity: 1;
  }

  .soc-section-holder .soc-bckg-image {
    background-repeat: no-repeat;
    opacity: 1;
    width: 756px;
    height: 295px;
    position: absolute;
    top: -190px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 0);
    background-size: contain;
  }

  .soc-section-holder .soc-section-content-right h2 {
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: rgb(18, 3, 46);
    opacity: 1;
    margin-bottom: 35px;
  }

  .soc-section-holder .soc-section-content-right {
    flex: 1 1;
    padding-left: 40px;
  }

  .clients-section-holder {
    padding-top: 85px;
    padding-bottom: 25px;
  }

  .clients-section-holder .clients-section-content h2 {
    text-align: center;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: rgb(18, 3, 46);
    opacity: 1;
    margin-bottom: 25px;
  }

  .why-section-holder .why-section-holder-content {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  .why-section-holder .why-section-content {
    display: flex;
    flex-direction: row;
    padding: 0px 60px;
  }

  .why-section-holder .why-content-item-block {
    text-align: left;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 1;
    background: transparent linear-gradient(89deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    padding: 0px 11px 5px 18px;
  }

  .why-section-holder .list-items-why-content-item {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 40px;
  }

  .partners-section-holder h2 {
    text-align: center;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: rgb(18, 3, 46);
    opacity: 1;
    margin-bottom: 80px;
  }

  .partners-section-holder {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  .partners-section-holder .partner-item-left-side-holder {
    padding-left: 77px;
    padding-top: 74px;
    width: 575px;
  }

  .partners-section-holder h3 {
    text-align: left;
    font: normal normal bold 30px/24px RalewayBold;
    letter-spacing: 0px;
    color: rgb(209, 191, 169);
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .partners-section-holder .flickity-prev-next-button.previous {
    left: 74px;
    top: 78%;
    background: transparent;
  }

  .partners-section-holder .flickity-prev-next-button.next {
    top: 78%;
    right: 80%;
    background: transparent;
  }

  .partners-section-holder .partner-item-left-side {
    height: 560px;
    width: 950px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  img.img-fluid.img-right-side-partners {
    object-fit: cover;
    height: 100%;
  }

  .partners-section-holder .partner-item-left-right {
    height: 560px;
    width: 660px;
    margin-left: 20px;
    position: relative;
  }

  .info-section-holder h2 {
    text-align: center;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
  }

  .info-section-holder {
    // height: 550px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
  }

  .contact-us-holder-main h2 {
    text-align: center;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    margin-bottom: 40px;
  }

  .contact-us-holder-main {
    background: #f2f0ed 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.1607843137);
    opacity: 1;
    padding-top: 100px;
    padding-bottom: 80px;
  }

  .custom-buttom-hero {
    background: transparent linear-gradient(91deg, #d900a1 18%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    color: #fff;
    padding: 20px 29px;
    font: normal normal bold 18px/19px RalewayBold;
    opacity: 0.79;
    position: fixed;
    bottom: 120px;
    right: 50px;
    z-index: 2;
  }

  .footer-content-holder.des-block .footer-logo-item img {
    height: 100%;
    width: 170px;
  }

  .footer-content-holder.des-block .footer-reserved-item {
    text-align: left;
    font: normal normal normal 14px/19px MontserratRegular;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    opacity: 1;
    margin-left: 30px;
  }

  .footer-content-holder.des-block li {
    display: inline-block;
    text-align: left;
    font: normal normal normal 14px/20px RalewayRegular;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
    margin-left: 35px;
    margin-bottom: 7px;
  }

  .footer-content-holder.des-block .footer-social-item {
    display: flex;
    margin-left: 35px;
  }

  .hero-section-holder.des-block {
    height: 600px;
    width: 100%;
  }

  .hero-section-holder.des-block #background-video {
    height: 600px;
    margin-top: 65px;
  }

  .service-hero-holder-content .service-hero-holder {
    padding: 100px 0 100px 90px;
  }

  .hero-bottom-line-spacing:before {
    border-bottom: 19px solid #12032f;
    border-left: 31px solid transparent;
    border-right: 31px solid transparent;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: 65px;
    -webkit-transform: translate(-50%) rotate(180deg);
    transform: translate(-50%) rotate(180deg);
    width: 0;
    z-index: 1;
  }

  .soc-section-holder .soc-section-holder-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 130px 40px 60px 40px;
  }

  .mission-vision-content-holder-block .mission-vision-item {
    align-items: center;
    display: flex;
    height: 700px;
    justify-content: center;
  }

  .mission-vision-content-holder-block .mission-item h1 {
    color: #0a0a50;
    font: normal normal 700 110px/130px RalewayBold;
    letter-spacing: 0;
    margin-bottom: 40px;
    opacity: 1;
    text-align: center;
  }

  .mission-vision-content-holder-block .vision-item h1 {
    text-align: center;
    font: normal normal bold 110px/131px RalewayBold;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
    margin-bottom: 40px;
  }

  .service-hero-holder-content h1 {
    text-align: left;
    font: normal normal bold 55px/75px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    max-width: 890px;
    position: relative;
    z-index: 1;
  }

  .cyber-info-hero-holder-content .cyber-info-hero-holder {
    padding: 75px 310px 70px 120px;
  }

  .cyber-info-hero-holder-content h2 {
    text-align: left;
    font: normal normal bold 21px/36px MontserratBold;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
    margin-bottom: 35px;
  }

  .title-description-holder-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px 50px 0px;
  }

  .title-description-holder-content h2 {
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    margin-bottom: 60px;
  }

  .two-block-image-text-holder-content h2 {
    text-align: left;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 50px;
    padding-right: 60px;
  }

  .contrast-two-block-image-text {
    background: #fff 0% 0% no-repeat padding-box !important;
    padding: 100px 100px 0px 100px;
  }

  .contrast-two-block-image-text h2 {
    color: #12032e !important;
  }

  .two-block-image-text-holder-content {
    background: #12032e 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 80px 100px;
  }

  .container-serive-image-text-block {
    padding: 120px 260px 90px 110px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .container-serive-image-text-block h1 {
    text-align: left;
    font: normal normal bold 57px/61px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 40px;
  }

  .service-our-service-item-holder-content
    .content-our-service-content-holder
    h2 {
    text-align: left;
    font: normal normal normal 50px/54px RalewayRegular;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-bottom: 50px;
  }

  .service-our-service-item-holder-content .guardian-holder-content-left {
    flex: 1.5;
  }

  .service-our-service-item-holder-content .content-our-service-content-holder {
    background: #f2f0ed 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 74px 140px 113px 110px;
  }

  .microsoft-block-holder-content .microsoft-block-holder-body {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 110px;
  }

  .resource-services-holder-content h3 {
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: #0a0a50;
    opacity: 1;
    text-align: center;
    margin-bottom: 40px;
  }

  .resource-services-holder-content .resource-services-content-center {
    padding: 0 60px;
    text-align: center;
  }

  .related-services-holder-content .related-services-content-center {
    padding: 0 60px;
    text-align: center;
  }

  .related-services-holder-content h3 {
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    margin-bottom: 40px;
  }

  .related-services-holder-content {
    background: transparent linear-gradient(70deg, #281d42 0%, #6517c8 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    padding: 70px 0px;
  }

  .related-services-holder-content .related-services-item-title {
    font: normal normal bold 20px/27px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 1;
  }

  .text-button-image-bckg-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 0px;
  }

  .images-block-content-holder .images-block-holder {
    flex: 1 1;
    padding: 70px 120px 122px 73px;
  }

  .images-block-content-holder h2 {
    text-align: left;
    font: normal normal bold 48px/54px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 55px;
    position: relative;
    z-index: 1;
  }

  .images-block-content-holder .images-block-right-holder h2::before {
    background: transparent
      linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
    content: "";
    height: 68px;
    width: 320px;
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: -1;
  }

  .images-block-content-holder .images-block-left-holder h2::before {
    background: transparent
      linear-gradient(91deg, rgb(217, 0, 161) 18%, rgb(101, 23, 200) 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
    content: "";
    height: 68px;
    width: 370px;
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: -1;
  }

  .four-blocks-holder-content h3 {
    text-align: left;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
    margin-bottom: 70px;
    height: 110px;
  }

  .four-blocks-holder-content .four-blocks-item {
    flex: 1 1;
    flex-basis: calc(50% - 20px);
    background: #f2f0ed 0% 0% no-repeat padding-box;
    border: 1px solid rgba(0, 0, 0, 0);
    opacity: 1;
    padding: 80px 70px 80px 70px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .block-image-item-holder h2 {
    text-align: left;
    font: normal normal bold 50px/54px RalewayBold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 65px;
  }

  .block-image-item-holder .block-image-main-block-content {
    padding: 110px 0px 110px 110px;
  }

  .expand-list-content-holder p {
    font: normal normal normal 18px/28px MontserratRegular;
    letter-spacing: 0px;
    color: #12032e;
    opacity: 1;
    padding: 0px 90px 40px;
  }

  .image-block-holder {
    text-align: center;
    margin: 80px 100px;
  }

  .expand-list-item-header h4 {
    font: normal normal bold 25px/30px RalewayBold;
    letter-spacing: 0px;
    color: #281d42;
    opacity: 1;
  }

  .service-info-holder h3 {
    text-align: left;
    font: normal normal normal 20px/30px MontserratMedium;
    letter-spacing: 0px;
    color: #0a0a50;
  }

  .service-info-holder {
    padding: 100px 230px 0px 120px !important;
  }

  .penetration-item-image img {
    width: 130px;
  }

  .service-our-service-item-holder-content .penetration-item-title {
    color: #281d42;
    font: normal normal 700 45px/48px RalewayBold;
    letter-spacing: 0;
    margin-top: 40px;
    opacity: 1;
    text-align: center;
  }

  .service-our-service-item-holder-content .penetration-block-list-item {
    flex: 1 1;
    padding: 0 15px;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  .shields-section-holder
    .shields-section-content-list-items
    .shields-section-content-item {
    width: calc(20% - 10px);
    margin-bottom: 20px;
  }

  .partner-item-left-right img {
    object-fit: cover;
    height: 100%;
    width: 80%;
  }

  .partners-section-holder .partner-item-left-side-holder {
    padding-left: 70px;
    padding-top: 60px;
    width: 485px;
  }
}

@media only screen and (max-width: 1080px) {
  .product-admin-holder-item {
    flex-direction: column;
  }
  .product-holder-admin-left-side {
    margin-right: 0px !important;
    flex: 1 !important;
  }
  .holder-left-side-main {
    display: flex;
  }

  .product-holder-admin-left-side-image {
    margin-right: 20px;
    flex: 1;
  }

  .product-holder-admin-left-side-text {
    flex: 1;
  }
}

@media only screen and (max-width: 768px) {
  .holder-left-side-main {
    display: flex;
    flex-direction: column;
  }
  .sidebar-main-content {
    left: -250px;
    z-index: 9999;
  }
  .main-content-conteiner {
    margin-left: 0px;
  }
  .header-main-content {
    background-color: #fff;
    border-bottom: 1px solid #e9ebec;
    left: 0;
    left: 0px;
    right: 0;
  }
  .sidebar-main-content.active {
    left: 0px;
  }

  .navbar-header-item-main {
    display: block;
  }
  .navbar-header-item-main span {
    display: block;
  }
}

@media only screen and (max-width: 360px) {
  .info-section-holder {
    height: 820px;
    padding: 0px 40px;
  }
}

@media only screen and (max-width: 389px) {
  .info-section-holder {
    height: 800px;
    padding: 0px 40px;
  }
}
