.table-grid-compontent-main {
  width: 100%;
  height: calc(100vh - 270px);
  background: #fff;
}

.remove-button-table {
  margin-left: 5px;
}
.button-export-to-excel {
  margin-bottom: 15px;
}
.mr-2 {
  margin-right: 10px !important;
}
